import React from "react";

const background = new URL("/public/images/background1.jpg", import.meta.url);
const sanduba = new URL("/public/images/sanduba.png", import.meta.url);

const Home = () => {
  return (
    <div className="relative min-h-screen">
      <div>
        <div className="absolute inset-0 overflow-hidden">
          <img
            src={background}
            alt="Lanchonete"
            className="absolute inset-0 object-cover w-full h-full"
            style={{ zIndex: -1 }}
          />
        </div>
        <div className="text-center flex flex-col sm:flex-row items-center ">
          <img
            src={sanduba}
            alt="lanche"
            className="size-[700px] md:size-[900px] object-cover"
          />
          <p className="text-emerald-50 mx-6 lg:mr-20 font-bold italic tracking-wide text-2xl md:text-2xl lg:text-3xl">
            Desde 2013, nossa lanchonete é o ponto de encontro na região oeste,
            onde os lanches são deliciosos e o atendimento é sempre acolhedor.
          </p>
        </div>
      </div>
      <div className="bg-green-500 p-1 relative">
        <p className="text-xl font-bold text-center tracking-wide">
          Horario de funcionamento: Terça a Domingo 6:30 às 23:30
        </p>
      </div>
      <div className="absolute inset-x-0 bottom-0 flex">
        <div className="bg-black w-full md:w-80% lg:w-3/4 flex justify-between">
          <iframe
            title="mapa da lanchonete"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7328.232209027126!2d-51.20776125382869!3d-23.311545878375572!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94eb5b6fefa3cae3%3A0x500531568c06d490!2sSalesio&#39;s%20Lanchonete!5e0!3m2!1spt-BR!2sbr!4v1713039778478!5m2!1spt-BR!2sbr"
            width="100%"
            height="450"
            style={{
              border: "0",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
            }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Home;
